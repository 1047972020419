<template>
   <div class="cont">
     <Breadcrumb :crumbs="crumbs"/>
     <set-message @publish="handlePublish" :text="{ t1: '活动名称', t2: '设置欢迎语', t3: '欢迎语' }" users="employeeCodeUsers" messageCont="employeeCodeWelcomes" :form="form">
       <template slot="appoint-crowd">
         <el-form-item label="加入标签">
           <el-button @click="selectTags">选择标签</el-button>
         </el-form-item>
       </template>
       <template slot="alert-text">
         <el-alert class="m-bottom-20" title="因企业微信限制，若使用员工已在【企业微信后台】配置了欢迎语，这里的欢迎语将不会生效。" type="warning" show-icon></el-alert>
       </template>
       <template slot="welcome-way">
         <el-form-item label="选择类型">
           <el-select v-model="form.welcomeWay" class="width-370" placeholder="请选择">
             <el-option v-for="(val, key) in { NONE: '不使用', NEW: '新建',/* DEFAULT: '默认'*/ }" :value="key" :label="val" :key="key"></el-option>
           </el-select>
         </el-form-item>
       </template>
       <!-- <template slot="other-set">
         <div class="other-set">
           <h6>其他设置</h6>
           <div class="p-left-30">
             <el-form-item label="好友自动添加">
               <el-radio-group v-model="form.confirm">
                 <el-radio label="0">开启</el-radio>
                 <el-radio label="1">关闭</el-radio>
               </el-radio-group>
             </el-form-item>
           </div>
         </div>
       </template>-->
     </set-message>
     <!-- 选择标签 -->
     <el-dialog title="选择标签" :visible.sync="tagVisible" width="450px">
       <el-checkbox-group v-model="form.employeeCodeTags">
         <el-checkbox v-for="item in tagList" :label="item" :key="item.tagId">{{ item.tagName }}</el-checkbox>
       </el-checkbox-group>
     </el-dialog>
   </div>
</template>
<script>
import { saveEmployeeCode, getTagList } from "@/api/data/wechat/liveCode";
import setMessage from "@/components/local/setMessage";
import { mapState } from 'vuex'
export default {
  data() {
    return {
      crumbs: new Map([
        ['数据中台', ''], ['企业微信', ''], ['渠道活码', '/enterprise_wechat/live_code'], ['创建活动', '']
      ]),
      form: {
        employeeCodeTags: [],
        welcomeWay: 'NONE',
        welcomeType: '',
        qrCode: '',
        // confirm: '0',
      },
      tagVisible: false,
      tagList: [],
      action: '',
      id: ''
    }
  },
  computed: { ...mapState(['hotelInfo']) },
  components: { setMessage },
  beforeDestroy() {
    sessionStorage.removeItem('activityInfo')
  },
  mounted() {
    this.action = this.$route.query.action
    this.action === 'edit' && this.getActivityInfo()
  },
  methods: {
    // 获取编辑信息
    getActivityInfo() {
      const activityInfo = JSON.parse(sessionStorage.getItem('activityInfo'));
      this.id = activityInfo.id
      for (const k in this.form) k in activityInfo && (this.form[k] = activityInfo[k])
    },
    // 选择标签群组
    selectTags() {
      this.tagVisible = true
      if (this.tagList.length > 0) return
      getTagList().then(({ success, records }) => {
        if (!success) return
        this.tagList = records.map(i => ({ tagId: i.id, tagName: i.name }))
      })
    },
    // 保存发布
    handlePublish(ruleForm) {
      const form = Object.assign({}, this.form, ruleForm)
      if (form.employeeCodeWelcomes.length === 2) form.welcomeType = form.employeeCodeWelcomes[1].msgType
      const params = { ...form, hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
      this.action === 'edit' && Object.assign(params, { id: this.id })
      saveEmployeeCode(params).then(({ success }) => {
        if (!success) return
        this.$router.push('/enterprise_wechat/live_code')
        this.$message({ message: '保存成功！', type: 'success' })
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
